var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"px-4 mx-auto bg-white border-t-2 shadow"},[_c('div',{staticClass:"flex justify-between lg:flex"},[_c('div',{staticClass:"flex lg:items-center"},[_c('div',{staticClass:"py-3 sm:hidden"},[_c('label',{staticClass:"sr-only",attrs:{"for":"tabs"}},[_vm._v("Select a tab")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.activePage),expression:"activePage"}],staticClass:"block w-full px-6 py-1 border-gray-300 rounded-md focus:ring-primary-500 focus:border-primary-500 text-primary-700",attrs:{"id":"tabs","name":"tabs"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.activePage=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){return _vm.openPage(_vm.activePage)}]}},[_c('option',{domProps:{"value":_vm.WorkflowDesignerViews.GraphView}},[_vm._v("Graph View")]),_c('option',{attrs:{"disabled":_vm.isOnPremise && _vm.isLocalStorage},domProps:{"value":_vm.WorkflowDesignerViews.TableView}},[_vm._v(" Table View ")]),_c('option',{attrs:{"disabled":_vm.isOnPremise},domProps:{"value":_vm.WorkflowDesignerViews.ResultsView}},[_vm._v(" Results View ")])])]),_c('div',{staticClass:"hidden sm:block"},[_c('div',{staticClass:"border-b border-gray-200"},[_c('nav',{staticClass:"flex -mb-px space-x-8",attrs:{"aria-label":"Tabs"}},[_c('div',{staticClass:"inline-flex items-center px-1 py-4 text-sm font-medium border-b-2 border-transparent group",class:!_vm.activeDropdown && _vm.activePage === _vm.WorkflowDesignerViews.GraphView
                                        ? 'text-primary-500 border-primary-500 font-bold'
                                        : 'text-gray-500 hover:text-gray-700 hover:border-gray-300 cursor-pointer',on:{"click":function($event){return _vm.openPage(_vm.WorkflowDesignerViews.GraphView)}}},[_c('svg',{staticClass:"w-6 h-6 -ml-0.5 mr-2",class:!_vm.activeDropdown && _vm.activePage === _vm.WorkflowDesignerViews.GraphView
                                            ? 'text-primary-500'
                                            : 'text-gray-400 group-hover:text-gray-500',attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5"}})]),_c('span',{staticClass:"whitespace-nowrap"},[_vm._v("Graph View")])]),_c('div',{staticClass:"inline-flex items-center px-1 py-4 text-sm font-medium border-b-2 border-transparent group",class:[
                                    _vm.activePage === _vm.WorkflowDesignerViews.TableView
                                        ? 'text-primary-500 border-primary-500'
                                        : 'text-gray-500 ',
                                    _vm.isOnPremise && _vm.isLocalStorage
                                        ? 'opacity-60'
                                        : 'hover:text-gray-700 hover:border-gray-300 cursor-pointer' ],on:{"click":function($event){!_vm.isOnPremise || !_vm.isLocalStorage
                                        ? _vm.openPage(_vm.WorkflowDesignerViews.TableView)
                                        : null}}},[_c('svg',{staticClass:"w-6 h-6 -ml-0.5 mr-2",class:[
                                        _vm.activePage === _vm.WorkflowDesignerViews.TableView
                                            ? 'text-primary-500'
                                            : 'text-gray-400 ',
                                        _vm.isOnPremise && _vm.isLocalStorage ? 'opacity-60' : 'group-hover:text-gray-500' ],attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M3 10h18M3 14h18m-9-4v8m-7 0h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"}})]),_c('span',{staticClass:"whitespace-nowrap"},[_vm._v("Table View")])]),_c('div',{staticClass:"inline-flex items-center px-1 py-4 text-sm font-medium border-b-2 border-transparent group",class:[
                                    _vm.activePage === _vm.WorkflowDesignerViews.ResultsView
                                        ? 'text-primary-500 border-primary-500'
                                        : 'text-gray-500 ',
                                    _vm.isOnPremise
                                        ? 'opacity-60'
                                        : 'hover:text-gray-700 hover:border-gray-300 cursor-pointer' ],on:{"click":function($event){!_vm.isOnPremise ? _vm.openPage(_vm.WorkflowDesignerViews.ResultsView) : null}}},[_c('svg',{staticClass:"w-6 h-6 -ml-0.5 mr-2",class:[
                                        _vm.activePage === _vm.WorkflowDesignerViews.ResultsView
                                            ? 'text-primary-500'
                                            : 'text-gray-400 ',
                                        _vm.isOnPremise ? 'opacity-60' : 'group-hover:text-gray-500' ],attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"}})]),_c('span',{staticClass:"whitespace-nowrap"},[_vm._v("Results View")])])])])])]),_c('div',{staticClass:"flex flex-row items-center space-x-4"},[(_vm.canUpgrade)?_c('UpgradeWidget',{attrs:{"disabled":_vm.isLoading},on:{"upgrade":function($event){return _vm.$emit('upgrade')},"upgrade-dry":function($event){return _vm.$emit('upgrade-dry')}}}):_vm._e(),_c('div',{staticClass:"flex items-center justify-center space-x-1 cursor-pointer group",class:{
                        'text-primary-500 border-primary-500 font-bold border-b-2 ': _vm.activeDropdown === 'configure',
                        'text-neutral-600 hover:text-neutral-700  ': _vm.activeDropdown !== 'configure',
                        'cursor-not-allowed opacity-50': _vm.disableClickEvent,
                    },on:{"click":function($event){return _vm.toggleDropdown('configure')}}},[_c('svg',{staticClass:"w-6 h-6",class:_vm.activeDropdown === 'configure'
                                ? 'text-primary-500'
                                : 'text-neutral-500 group-hover:text-neutral-600  ',attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"}})]),_c('span',{staticClass:"justify-center hidden text-sm capitalize lg:flex"},[_vm._v(" Configure ")]),_c('svg',{staticClass:"w-4 h-4",attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M19 9l-7 7-7-7"}})])]),(!_vm.isDeprecated)?_c('div',{staticClass:"flex flex-row items-center justify-center space-x-1 cursor-pointer group",class:{
                        'text-primary-500 border-primary-500 font-bold border-b-2 ': _vm.activeDropdown === 'triggers',
                        'text-neutral-600 hover:text-neutral-700  ': _vm.activeDropdown !== 'triggers',
                        'cursor-not-allowed opacity-50': _vm.disableClickEvent,
                    },on:{"click":function($event){return _vm.toggleDropdown('triggers')}}},[_c('div',{staticClass:"flex flex-row items-center justify-center space-x-1"},[(_vm.hasAccessToTriggers && !_vm.hasInvalidInputAssetInTriggers)?_c('CalendarIcon',{staticClass:"w-6 h-6",class:_vm.activeDropdown === 'triggers'
                                    ? 'text-primary-500'
                                    : 'text-neutral-500 group-hover:text-neutral-600  '}):_c('v-popover',{staticClass:"flex flex-row items-center",attrs:{"id":_vm.uuidv4(),"trigger":"hover focus","placement":"top","delay":{ show: 500 }}},[_c('ExclamationIcon',{staticClass:"w-6 h-6",class:_vm.activeDropdown === 'triggers'
                                        ? 'text-orange-400'
                                        : 'text-orange-400 group-hover:text-orange-600 '}),_c('ul',{attrs:{"slot":"popover"},slot:"popover"},[(!_vm.hasAccessToTriggers)?_c('li',[_vm._v(" A trigger has been denied access to one of the pipelines or assets utilised. ")]):_vm._e(),(_vm.hasInvalidInputAssetInTriggers)?_c('li',[_vm._v(" An asset used in a trigger is no longer used as an input asset of this pipeline. ")]):_vm._e()])],1),_c('span',{staticClass:"justify-center hidden text-sm capitalize lg:flex"},[_vm._v(" Schedule ")])],1),_c('svg',{staticClass:"w-4 h-4",attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M19 9l-7 7-7-7"}})])]):_vm._e(),(_vm.isLoading)?_c('div',{staticClass:"flex items-center justify-center text-neutral-600 group hover:text-neutral-800"},[_c('span',{staticClass:"justify-center hidden text-sm capitalize lg:flex"},[_vm._v(" Loading... ")])]):(!_vm.isDeprecated)?[(!_vm.isFinalised || _vm.validationErrors.length > 0)?_c('ValidationWidget',{attrs:{"errors":_vm.validationErrors,"isActive":_vm.activeDropdown === 'validation'},on:{"toggle":function($event){return _vm.toggleDropdown('validation')}}}):_vm._e(),_c('v-popover',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isFinalised),expression:"!isFinalised"}],staticClass:"flex flex-row items-center",attrs:{"id":_vm.uuidv4(),"trigger":"hover focus","placement":"top","delay":{ show: 500 }}},[_vm._t("default",function(){return [(!_vm.isFinalised)?_c('div',{staticClass:"flex flex-row items-center space-x-2 rounded text-primary-500 hover:text-primary-700",class:{
                                    ' cursor-not-allowed opacity-50':
                                        _vm.validationErrors && _vm.validationErrors.length > 0,
                                    'cursor-pointer': _vm.validationErrors && _vm.validationErrors.length === 0,
                                },on:{"click":function($event){_vm.validationErrors.length === 0 ? _vm.finaliseWorkflow() : null}}},[_c('div',{staticClass:"flex justify-center"},[_c('svg',{staticClass:"w-6 h-6",attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"}})])]),_c('span',{staticClass:"justify-center hidden text-sm capitalize lg:flex"},[_vm._v(" Finalise ")])]):_vm._e()]}),_c('div',{staticClass:"flex flex-col flex-shrink-0 w-auto text-white border rounded shadow-lg flex-end bg-neutral-900 border-neutral-900 flex-no-grow",attrs:{"slot":"popover"},slot:"popover"},[(_vm.validationErrors && _vm.validationErrors.length === 0)?[_c('p',[_vm._v("You can run the analyticis pipeline in the whole input dataset(s)")]),_c('p',[_vm._v("and store results only by finalising it")])]:[_c('p',[_vm._v("You can only finalise an analytics pipeline once")]),_c('p',[_vm._v("all validation errors have been resolved")])]],2)],2),(_vm.isFinalised || _vm.isLocked)?_c('LockWidget',{attrs:{"disabled":_vm.isLoading,"canBeReopened":_vm.canBeReopened,"isLocked":_vm.isLocked},on:{"unlock":function($event){return _vm.$emit('unlock')}}}):_vm._e(),(_vm.isFinalised)?_c('RunWidget',{attrs:{"runningExecution":_vm.runningExecution,"disabled":_vm.validationErrors.length > 0 || _vm.isLocked},on:{"run":function($event){_vm.runningExecution ? null : _vm.runWorkflow()}}}):_vm._e()]:_vm._e()],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }